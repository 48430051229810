import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import doFormMapApi from 'apis/disApi/doFormMapApi'
import { DEFAULT_REPEAT_RELOAD_MAP_SECONDS } from '../useVehicleLocation'
import moment from 'moment'

function useDoformsMapCurrentDeviceQuery({
    tileKey,
    deviceKey,
    apiCallIntervalInSeconds = DEFAULT_REPEAT_RELOAD_MAP_SECONDS,
    beginDate,
    endDate,
}) {
    const { environment } = useSelector((state) => state)
    const [lastTrackData, setLastTrackData] = useState([])
    const { data, isLoading, isFetching } = useQuery({
        queryKey: ['doformsMapTile', tileKey, deviceKey],
        queryFn: () => doFormMapApi.getDeviceLastTrack(
            deviceKey,
            environment.apiToken
        ),
        // Feature Reload this API every `apiCallIntervalInSeconds` seconds
        refetchInterval: apiCallIntervalInSeconds * 1000,
        enabled: Boolean(deviceKey),
    })

    useEffect(() => {
        if (!deviceKey) {
            setLastTrackData([])
            return
        }
        if (isLoading || isFetching) return

        const lastTrack = data?.data?.lastTrack
        if (!lastTrack) {
            setLastTrackData([])
            return
        }

        const lastTrackMoment = moment(lastTrack.date)
        if (lastTrackMoment.isBetween(beginDate, endDate, '[]')) {
            setLastTrackData([lastTrack])
        } else {
            setLastTrackData([])
        }
    }, [deviceKey, data, isLoading, isFetching, beginDate, endDate])
    return {
        data: lastTrackData,
        isLoading: !deviceKey ? false : isLoading,
        isFetching: !deviceKey ? false : isLoading,
    }
}

export default useDoformsMapCurrentDeviceQuery
