import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getAllViews } from '../../../data/dataServices'

const viewKeys = {
	all: ['views'],
}

function useView({ config = {} } = {}) {
	const { environment } = useSelector((state) => state)

	const { data, isLoading, isFetching, refetch } = useQuery(
		viewKeys.all,
		() => getAllViews(environment.apiToken, environment.account.key),
		{
			staleTime: Infinity,
			...config,
		}
	)

	const reloadViews = () => {
		refetch()
	}

	return { views: data?.data ?? [], viewLoading: isLoading || isFetching, reloadViews }
}

export default useView
