import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import menuGroupApi from 'apis/disApi/menuGroupApi'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const groupKeys = {
	all: ['menu-groups'],
}

function useGroupQuery() {
	const { environment } = useSelector((state) => state)
	const queryClient = useQueryClient()

	const { data, isLoading } = useQuery(groupKeys.all, async () => {
		const res = await menuGroupApi.getAll(environment.apiToken)
		return res?.data === '' ? [] : res?.data
	})

	const groupOptions = useMemo(() => {
		if (data?.length > 0) {
			return data?.map((tab) => ({
				...tab,
				label: tab.name,
				value: tab.key,
			}))
		}
		return []
	}, [data])

	const assignToMenuMutation = useMutation(menuGroupApi.assignToMenu, {
		onSuccess: () => queryClient.invalidateQueries(groupKeys.all),
	})

	const addMutation = useMutation(menuGroupApi.add)

	const removeMutation = useMutation(menuGroupApi.remove)

	const updateMutation = useMutation(menuGroupApi.update)

	return { groups: data, groupOptions, isGroupLoading: isLoading, assignToMenuMutation, updateWebGroup: updateMutation, addWebGroup: addMutation, removeWebGroup: removeMutation }
}

export default useGroupQuery
