import { useCallback } from 'react'

import { Box } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import PropTypes from 'prop-types'
import { Responsive, WidthProvider } from 'react-grid-layout'
import ChartTile from '../tile/chart/ChartTile'
import DatagridTile from '../tile/datagrid/DatagridTile'
import ImageViewerTile from '../tile/image-viewer/ImageViewerTile'
import BrowserTile from '../tile/browser/BrowserTile'
import MapTile from '../tile/map/MapTile'
import PageTile from '../tile/page/PageTile'
import MobileUnitsTile from '../tile/mobile-unit/MobileUnitsTile'
import FeedTile from '../tile/feed/FeedTile'
import DoformsMapTile from '../tile/doFormsMap/DoformsMapTile'
import TextSearchTile from '../tile/text-search/TextSearchTile'
import DoFormsFormTile from '../tile/doforms-form/DoformsFormTile'
import { isJson } from 'utils/functions/helpers'
import { checkKeyIsExist } from '../tile/utils/helpers'
import { isEqual } from 'lodash'
import { DEFAULT_CHECKED_OPTIONS } from 'components/pages/dis-v2/_components/OtherOptions'
import { DEFAULT_DOFORM_CHECKED_OPTIONS } from '../tile/doforms-form/DoformsForm'
//import { checkKeyIsExist } from '../utils/helpers'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const tileComponents = {
	datagrid: DatagridTile,
	GeoMap: MapTile,
	Page: PageTile,
	Chart: ChartTile,
	FormList: DatagridTile,
	ImageViewer: ImageViewerTile,
	MobileUnits: MobileUnitsTile,
	Feed: FeedTile,
	DoformsMap: DoformsMapTile,
	Browser: BrowserTile,
	TextSearch: TextSearchTile,
	DoFormsForm: DoFormsFormTile,
}

function renderTile(item) {
	const TileComponent = tileComponents[item?.type]
	return TileComponent ? <TileComponent tile={item} /> : null
}

function DragDropLayout({
	layout,
	isActive = false,
	isOnDashboard = false,
	onLayoutChange = () => {},
	isSettingDialog = false,
}) {
	const renderItemComponent = useCallback(() => {
		return layout.map((item) => {
			let hideBlankBox = item.type === 'Blank' && !isSettingDialog
			let hideTile = false
			if (item.type === 'DoFormsForm' && item?.settings && isJson(item?.settings)) {
				const parsedSettings = JSON.parse(item.settings)
				if (isEqual(parsedSettings?.otherOptionsChecked, DEFAULT_CHECKED_OPTIONS)) {
					parsedSettings.otherOptionsChecked = DEFAULT_DOFORM_CHECKED_OPTIONS
				}
				if (
					parsedSettings?.otherOptionsChecked?.length > 0 &&
					checkKeyIsExist('showInDashboard', parsedSettings?.otherOptionsChecked) === false
				) {
					hideTile = true
				}
			}

			return hideBlankBox || hideTile ? null : (
				<Box
					key={isOnDashboard ? item.key : item.i}
					data-grid={item}
					sx={{
						boxShadow: isOnDashboard ? 1 : undefined,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: blueGrey[50],
						'& .react-resizable-handle, & .react-resizable-handle-se': {
							display: isOnDashboard ? 'none !important' : undefined,
						},
					}}
				>
					{isOnDashboard ? renderTile(item) : item.i}
				</Box>
			)
		})
	}, [isOnDashboard, layout])
	return (
		<ResponsiveReactGridLayout
			cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
			layouts={{ lg: layout }}
			rowHeight={95}
			onLayoutChange={(newLayout) => onLayoutChange?.(newLayout)}
		>
			{renderItemComponent()}
		</ResponsiveReactGridLayout>
	)
}

DragDropLayout.propTypes = {
	layout: PropTypes.array.isRequired,
	isActive: PropTypes.bool,
	isOnDashboard: PropTypes.bool,
	onLayoutChange: PropTypes.func,
}

export default DragDropLayout
