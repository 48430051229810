import ConstructionIcon from '@mui/icons-material/Construction'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SettingsIcon from '@mui/icons-material/Settings'
import VideocamIcon from '@mui/icons-material/Videocam'
import { useQuery } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import MenuProvider from 'components/pages/dis-v2/contexts/MenuContext'
import useMenuQuery from 'components/pages/dis/hooks/useMenuQuery'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { checkAdminRole, isJson } from 'utils/functions/helpers'
import DisNavTabs from '../../core/Layouts/DisNavTabs'
import NavLayout from '../../core/Layouts/NavLayout'
import { sortByPosition } from '../../pages/dis/helpers'

export const MY_MENU_KEY = 'my-menus'
export const RIGHT_SIDE_MENU_LIST = [
	{
		name: 'question',
		icon: QuestionMarkIcon,
	},
	{
		name: 'video',
		icon: VideocamIcon,
	},
	{
		name: 'headset',
		icon: HeadsetMicIcon,
	},
]

export const RIGHT_SIDE_MENU_LIST_WITH_DEFAULT = [
	{
		name: 'contruction',
		icon: ConstructionIcon,
	},
	{
		name: 'setting',
		icon: SettingsIcon,
	},
]

const DisLayout = ({ children }) => {
	const { environment } = useSelector((state) => state)
	const [isShowAllMenus, setIsShowAllMenus] = useState(false)
	const [currentMenu, setCurrentMenu] = useState('default')

	const isAdmin = checkAdminRole(environment?.userCurrent)

	const { data, isFetching } = useQuery([MY_MENU_KEY], () => menuApi.getMy(environment.apiToken))

	const { data: allMenus, isLoading: isLoadingMenus } = useMenuQuery({
		enabled: isAdmin,
	})

	const menuSettings = useMemo(() => {
		if (!isShowAllMenus) {
			if (data?.data?.isDefault) {
				return RIGHT_SIDE_MENU_LIST.map((item) => item.name)
			}
			return isJson(data?.data?.settings) ? JSON.parse(data?.data?.settings) : []
		} else {
			let settingsList = allMenus?.data?.map((item) =>
				isJson(item?.settings) ? JSON.parse(item?.settings) : []
			)

			const flattenedList = _.flatMap(settingsList)
			const uniqueList = _.uniq(flattenedList)

			return uniqueList
		}
	}, [data?.data, allMenus?.data, isShowAllMenus])

	const menuList = useMemo(() => {
		try {
			// isShowAllMenus === false then show default menu
			if (!isShowAllMenus) {
				if (isFetching || isLoadingMenus) return [];

				// check user default menu (isDefault === true)
				const currentUserMenu = data?.data;
				if (currentMenu === 'default' || currentUserMenu?.isDefault) return currentUserMenu.items ?? [];

				// find selected menu key in allMenus
				const menuItems = allMenus?.data?.filter((item) => item.isDefault && item.key === currentMenu)
				if (menuItems?.length > 0) {
					return (
						menuItems?.flatMap((item) => {
							return item?.items?.map((subItem) => ({ ...subItem, menuKey: item.key }))
						}) ?? []
					)
				}
				return [];
			} else {
				// find selected menu key in allMenus
				const menuItems = allMenus?.data?.filter(
					(item) => !item.isDefault && item.key === currentMenu
				)
				if (menuItems?.length > 0) {
					return (
						menuItems?.flatMap((item) => {
							return item?.items?.map((subItem) => ({ ...subItem, menuKey: item.key }))
						}) ?? []
					)
				}
				return []
			}
		} catch {
			return []
		}
	}, [isFetching, isLoadingMenus, data?.data, allMenus?.data, isShowAllMenus, currentMenu])

	const sortedMenuList = useMemo(() => {
		return sortByPosition(menuList)
	}, [menuList])

	const defaultChildMenu = useMemo(() => {
		return menuList.find(menu => menu.isDefault);
	}, [menuList]);

	return (
		<MenuProvider>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
			/>

			<NavLayout />
			<DisNavTabs
				menuItemList={sortedMenuList}
				isShowAllMenus={isShowAllMenus}
				setIsShowAllMenus={setIsShowAllMenus}
				isShowIconLoading={isFetching || isLoadingMenus}
				menuSettings={menuSettings}
				setCurrentMenu={setCurrentMenu}
				listAllMenu={allMenus?.data ?? []}
				defaultChildMenu={defaultChildMenu}
			/>
			{children}
		</MenuProvider>
	)
}

export default DisLayout
